import axios from "axios";
import {
  AUTH_BASE_PATH,
  PATH_NAMES,
  TOKEN_STRING,
} from "../constants/index.constant";
import { mode } from "../helpers/index.helper";

const getBaseUrl = (): string | any => {
  const host = window.location.host;
  if (host.includes("localhost") || host.includes("netlify")) {
    return `${process.env.REACT_APP_BASE_URL}/api/v1`;
  } else {
    return `${process.env.REACT_APP_BASE_URL}/api/v1`;
  }
};

export const instance = axios.create({
  baseURL: getBaseUrl(),
});

instance.interceptors.request.use(
  function (config: any): any {
    const token: string | any = localStorage.getItem(TOKEN_STRING);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      const token: string | any = localStorage.getItem(TOKEN_STRING);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .post(`${AUTH_BASE_PATH}/logout`, {}, { headers })
        .then(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        })
        .catch(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        })
        .finally(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        });
    }
    return Promise.reject(error);
  }
);

export const kycInstance = axios.create({
  baseURL:
    mode === "customer.test"
      ? process.env.REACT_APP_KYC
      : `${process.env.REACT_APP_BASE_URL}/kyc`,
});

kycInstance.interceptors.request.use(
  function (config: any): any {
    const token: string | any = localStorage.getItem(TOKEN_STRING);
    const mode: string | any = localStorage.getItem("domain");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["mode"] = `${mode}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const cashierInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
});

cashierInstance.interceptors.request.use(
  function (config: any): any {
    const token: string | any = localStorage.getItem(TOKEN_STRING);
    const mode: string | any = localStorage.getItem("domain");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["mode"] = `${mode}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

cashierInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      const token: string | any = localStorage.getItem(TOKEN_STRING);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .post(`${AUTH_BASE_PATH}/logout`, {}, { headers })
        .then(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        })
        .catch(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        })
        .finally(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        });
    }
    return Promise.reject(error);
  }
);

const username = process.env.REACT_APP_MOENGAGE_APPID;
const password = process.env.REACT_APP_MOENGAGE_API_KEY;
const base64Credentials = btoa(`${username}:${password}`);

export const MoengageInstance = axios.create({
  baseURL: process.env.REACT_APP_MOENGAGE_API,
  headers: {
    Authorization: `Basic ${base64Credentials}`,
    "Content-Type": "application/json",
    "MOE-APPKEY": process.env.REACT_APP_MOENGAGE_APPID,
  },
});

const billPaymentURl =
  process.env.REACT_APP_BILL_PAYMENT_API ??
  `https://pooler-vas.swim.poolerapp.com/vas`;

export const billPaymentInstance = axios.create({
  baseURL: `${billPaymentURl}`,
});

billPaymentInstance.interceptors.request.use(
  function (config: any): any {
    const token: string | any = localStorage.getItem(TOKEN_STRING);
    // const mode: string | any = localStorage.getItem("domain");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["mode"] = `live`;
      config.timeout = 4 * 60 * 1000;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

billPaymentInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      const token: string | any = localStorage.getItem(TOKEN_STRING);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .post(`${AUTH_BASE_PATH}/logout`, {}, { headers })
        .then(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        })
        .catch(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        })
        .finally(() => {
          localStorage.removeItem(TOKEN_STRING);
          localStorage.clear();
          window.location.replace(`/auth/merchant${PATH_NAMES.signin}`);
        });
    }
    return Promise.reject(error);
  }
);
