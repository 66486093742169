import React, { useLayoutEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { toast } from "react-toastify";
import Camera from "../../../component/Camera/Camera";
import {
  base64ToBlob,
  encryptKyc,
  generateUniqueId,
  mode,
  signatureHarsh,
  validateEmail,
} from "../../../helpers/index.helper";
import { RELATIONSHIP_WITH_NOK } from "../../../utils/data";
import { formprops } from "../../../utils/interfaces";
// import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import { Country, State } from "country-state-city";
import Image from "react-bootstrap/Image";
import "react-circular-progressbar/dist/styles.css";
import {TOKEN_STRING} from "../../../constants/index.constant";

function MandatoryUnregisteredFields({
  formValues,
  handleChange,
  data,
  isDisabled,
  setFormData,
}: formprops): JSX.Element {
  const [modalShow, setModalShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [stateValue, setStateValue] = useState<any>({});
  const [uploading, setUploading] = useState<boolean>(false);
  const [size, setSize] = useState([0, 0]);
  const key = generateUniqueId();

  const COUNTRY_JSON: any = Country.getAllCountries().map((i: any) => {
    return { value: i?.name, label: i?.name, isoCode: i?.isoCode };
  });

  const defaultVal = [
    {
      value: data?.country || formValues?.country,
      label: data?.country || formValues?.country,
    },
  ];

  const defaultStateVal = [{ label: data?.state, value: data?.state }];

  const STATE_BY_COUNTRYCODE = (stateCode: string) => {
    if (stateCode) {
      const result = State.getStatesOfCountry(stateCode);
      const stateJson = result.map((i: any) => {
        return { label: i?.name, value: i?.name };
      });
      return stateJson;
    }
  };

  const colourStyles: any = {
    control: (styles: any, state: any) => {
      return {
        ...styles,
        backgroundColor: "#fff",
        // border: `${
        //   state?.hasValue === true ? "1px solid #00c9a7" : "1px solid"
        // }`,
        border:
          data?.country || data?.state
            ? "1px solid #00c9a7 !important"
            : "1px solid",
        minHeight: "45px",
        overflow: "hidden",
      };
    },
  };

  const handlePictureUpload = async (file: any) => {
    setUploading(true);
    const baseUrl =
      mode === "customer.test"
        ? process.env.REACT_APP_KYC
        : process.env.REACT_APP_BASE_URL;

    const Id: any = localStorage.getItem("merchant_compliance_id");
    const message = Id && JSON.stringify({ id: Id });
    const x_signature = encryptKyc(message, signatureHarsh);

    const url =
      mode === "customer.test"
        ? `${baseUrl}/upload/${Id}`
        : `${baseUrl}/kyc/upload/${Id}`;

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(url, formData, {
        headers: { "x-compliance-signature": x_signature, "Authorization": localStorage.getItem(TOKEN_STRING) },
        onUploadProgress: (progressEvent: any) => {
          if (progressEvent?.lengthComputable) {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          }
        },
      });
      const imageUrl = response?.data?.data?.url;

      if (imageUrl) {
        toast.success("File uploaded successfully.");
        const e = { target: { id: "image", name: "image", value: imageUrl } };
        await handleChange(e);
        setUploading(false);
      }
    } catch (error) {
      toast.error("File upload failed");
      setUploading(false);
      throw error;
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const startLivenessTest = () => {
    try {
      const YVSDK = typeof window !== "undefined" ? window?.YouverifySDK : null;
      const liveness = new YVSDK.liveness({
        publicMerchantKey: process.env.REACT_APP_YOU_VERIFY_MERCHANT_KEY,
        dev: mode === "internal.test" ? true : false,
        personalInformation: {
          firstName: formValues?.first_name,
        },
        onSuccess: async (data: { photo: string }) => {
          const img = await base64ToBlob(data?.photo);
          if (img) {
            handlePictureUpload(img);
            toast.success("liveliness check completed successfully");
          }
        },
        onClose: () => {
          toast.success("liveliness check completed successfully");
        },
      });
      liveness.initialize();
      liveness.start();
    } catch (error) {
      toast.warn("Pls input your first name");
      return error;
    }
  };

  return (
    <div
      className="w-100 w-md-75 w-lg-75 content-space-t-0 content-space-t-lg-1 content-space-b-1 mx-auto"
      style={{ maxWidth: "50rem" }}
    >
      <Camera
        show={modalShow}
        onHide={() => setModalShow(false)}
        saveImage={handlePictureUpload}
      />
      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="first_name">
            First Name*
          </label>
          <input
            type="text"
            className={`form-control 
            ${
              formValues?.first_name &&
              formValues?.first_name?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }
            ${
              data?.first_name && data?.first_name?.length > 0 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }${
              formValues?.first_name &&
              formValues?.first_name?.length > 0 &&
              data?.first_name &&
              data?.first_name?.length > 0 &&
              isDisabled === true
                ? "border border-secondary"
                : "border border-secondary"
            }
            `}
            name="first_name"
            id="first_name"
            tabIndex={1}
            defaultValue={data?.first_name}
            placeholder=""
            aria-label="first_name"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="last_name">
            Last name*
          </label>
          <input
            type="text"
            className={`form-control 
            ${
              formValues?.last_name &&
              formValues?.last_name?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }
            ${
              data?.last_name && data?.last_name?.length > 0 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.last_name &&
              formValues?.last_name?.length > 0 &&
              data?.last_name &&
              data?.last_name?.length > 0 &&
              isDisabled === true
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="last_name"
            id="last_name"
            tabIndex={1}
            defaultValue={data?.last_name}
            placeholder=""
            aria-label="last_name"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
      </div>

      {(isDisabled === true && !data?.middle_name) ||
      (isDisabled === true && !data?.dob) ? null : (
        <div className="row mb-3 mb-md-4 mb-lg-4">
          {isDisabled === true && !data?.middle_name ? null : (
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
              <label className="form-label" htmlFor="middle_name">
                Middle name
              </label>
              <input
                type="text"
                className={`form-control ${
                  formValues?.middle_name &&
                  formValues?.middle_name?.length > 0 &&
                  !isDisabled
                    ? "border border-secondary "
                    : "border border-secondary"
                }
            ${
              data?.middle_name && data?.middle_name?.length > 0 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
                  formValues?.middle_name &&
                  formValues?.middle_name?.length > 0 &&
                  data?.middle_name &&
                  data?.middle_name?.length > 0 &&
                  isDisabled === true
                    ? "border border-secondary"
                    : "border border-secondary"
                }`}
                name="middle_name"
                id="middle_name"
                tabIndex={1}
                value={formValues?.middle_name}
                placeholder=""
                aria-label="middle name"
                onChange={handleChange}
                readOnly={isDisabled}
              />
            </div>
          )}

          {isDisabled === true && !data?.dob ? null : (
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
              <label className="form-label" htmlFor="middle_name">
                Date of birth (DOB linked to BVN)*
              </label>
              <input
                type="date"
                name="dob"
                id="dob"
                defaultValue={data?.dob}
                onChange={handleChange}
                className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                  formValues?.dob && formValues?.dob?.length > 0 && !isDisabled
                    ? "border border-secondary "
                    : "border border-secondary"
                } ${
                  data?.dob && data?.dob?.length > 0 && !isDisabled
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                readOnly={isDisabled}
              />
            </div>
          )}
        </div>
      )}

      {isDisabled === true && !data?.dob ? null : (
        <div
          className={`row d-sm-block d-md-none d-lg-block d-xl-block mb-3 mb-md-4 mb-lg-4`}
          hidden
        >
          {isDisabled === true && !data?.dob ? null : (
            <div className="col" hidden>
              <label className="form-label" htmlFor="middle_name">
                Date of birth (DOB linked to BVN)*
              </label>
              <input
                type="date"
                name="dob"
                id="dob"
                defaultValue={data?.dob}
                onChange={handleChange}
                className={`js-daterangepicker form-control daterangepicker-custom-input  ${
                  formValues?.dob && formValues?.dob?.length > 0 && !isDisabled
                    ? "border border-secondary "
                    : "border border-secondary"
                } ${
                  data?.dob && data?.dob?.length > 0 && !isDisabled
                    ? "border border-secondary "
                    : "border border-secondary"
                }`}
                readOnly={isDisabled}
              />
            </div>
          )}
        </div>
      )}

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="email">
            Email Address (linked to BVN)*
          </label>
          <input
            type="email"
            className={`form-control 
            ${
              formValues?.email &&
              formValues?.email?.length > 0 &&
              validateEmail(formValues?.email) === true &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }
            ${
              formValues?.email &&
              formValues?.email?.length > 0 &&
              validateEmail(formValues?.email) === false &&
              !isDisabled
                ? "border border-danger "
                : "border border-secondary"
            }
            ${
              data?.email && data?.email?.length > 0 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.email &&
              formValues?.email?.length > 0 &&
              data?.email &&
              data?.email?.length > 0 &&
              isDisabled === true
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="email"
            id="email"
            tabIndex={1}
            defaultValue={data?.email}
            placeholder=""
            aria-label="email"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
          <span className="invalid-feedback">
            Please enter a valid email address.
          </span>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="phone_number">
            Phone Number (linked to BVN)*
          </label>
          <PhoneInput
            country={"ng"}
            value={data?.phone_number}
            disabled={isDisabled}
            onChange={(phoneNumber: any) =>
              handleChange({
                target: { name: "phone_number", value: phoneNumber },
              })
            }
            inputClass={`form-control form-control-lg w-100
            ${
              formValues?.phone_number &&
              formValues?.phone_number?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }
            ${
              data?.phone_number &&
              data?.phone_number?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.phone_number &&
              formValues?.phone_number?.length > 0 &&
              data?.phone_number &&
              data?.phone_number?.length > 0 &&
              isDisabled === true
                ? "border border-secondary"
                : "border border-secondary"
            }`}
          />
        </div>
      </div>

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="bvn">
            BVN*
          </label>
          <input
            type="number"
            className={`form-control  ${
              formValues?.bvn && formValues?.bvn?.length > 11 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.bvn && formValues?.bvn?.length === 11 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.bvn &&
              formValues?.bvn?.length < 11 &&
              formValues?.bvn?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }  ${
              formValues?.bvn && formValues?.bvn?.length === 11 && isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="bvn"
            id="bvn"
            tabIndex={1}
            defaultValue={data?.bvn}
            placeholder=""
            aria-label="bvn"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.target.value.replace(/[^0-9]/g, "");
              handleChange(e);
            }}
            required
            readOnly={isDisabled}
          />
          <span className="invalid-feedback">
            Bvn must not be less or greater than 11 characters.
          </span>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="country">
            Country*
          </label>
          {isDisabled === true ? (
            <input
              name="country"
              id="country"
              tabIndex={1}
              defaultValue={data?.country}
              placeholder=""
              aria-label="country"
              required
              readOnly={isDisabled}
              className={`form-control border border-secondary`}
            />
          ) : (
            <Select
              aria-label="country"
              options={COUNTRY_JSON}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.label}
              onChange={(option: any) => {
                setStateValue({ ...option });
                const e = { target: { name: "country", value: option?.value } };
                handleChange(e);
              }}
              classNamePrefix="react-select"
              placeholder="Select..."
              isClearable
              isSearchable
              styles={colourStyles}
              defaultValue={defaultVal[0]}
              defaultInputValue={data?.country}
            />
          )}
        </div>
      </div>

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className={`col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0`}>
          <label className="form-label" htmlFor="state">
            State*
          </label>
          {isDisabled === true ? (
            <input
              name="state"
              id="state"
              tabIndex={1}
              defaultValue={data?.state}
              placeholder=""
              aria-label="state"
              required
              readOnly={isDisabled}
              className={`form-control border border-secondary`}
            />
          ) : (
            <Select
              aria-label="state"
              options={STATE_BY_COUNTRYCODE(stateValue?.isoCode)}
              getOptionLabel={(option: any) => option.label}
              getOptionValue={(option: any) => option.label}
              onChange={(option) => {
                const e = { target: { name: "state", value: option?.value } };
                handleChange(e);
              }}
              classNamePrefix="react-select"
              placeholder="Select..."
              isClearable
              isSearchable
              styles={colourStyles}
              defaultInputValue={formValues?.state}
              defaultValue={defaultStateVal[0]}
            />
          )}
        </div>

        {isDisabled === true && !data?.city ? null : (
          <>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
              <label className="form-label" htmlFor="country">
                City*
              </label>
              <input
                type="text"
                className={`form-control 
            ${
              formValues?.city && formValues?.city?.length > 0 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }
            ${
              data?.city && data?.city?.length > 0 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
                  formValues?.city &&
                  formValues?.city?.length > 0 &&
                  data?.city &&
                  data?.city?.length > 0 &&
                  isDisabled
                    ? "border border-secondary"
                    : "border border-secondary"
                }`}
                name="city"
                id="city"
                tabIndex={1}
                defaultValue={data?.city}
                placeholder="City"
                aria-label="city"
                onChange={handleChange}
                required
                readOnly={isDisabled}
              />
            </div>
          </>
        )}
      </div>

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col">
          <label className="form-label" htmlFor="address">
            Address*
          </label>
          <input
            type="text"
            className={`form-control 
            ${
              formValues?.address &&
              formValues?.address?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }
            ${
              data?.address && data?.address?.length > 0 && !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.address &&
              formValues?.address?.length > 0 &&
              data?.address &&
              data?.address?.length > 0 &&
              isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="address"
            id="address"
            tabIndex={1}
            defaultValue={data?.address}
            placeholder=""
            aria-label="address"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
      </div>

      <div className="row mb-3 mb-md-4 mb-lg-4 text-center">
        {isDisabled === true && data ? (
          <div className="col">
            <Image
              src={data?.image}
              style={{ width: "200px", height: "250px" }}
              thumbnail
            />
          </div>
        ) : (
          <>
            {formValues?.image && (
              <div className="col mt-3">
                <Image
                  src={formValues?.image}
                  style={{ width: "200px", height: "250px" }}
                  thumbnail
                />
              </div>
            )}
            <div className="d-flex flex-column align-items-center justify-content-center mt-3">
              <div className="">
                {mode === "customer.test" ? (
                  <label
                    htmlFor={`picture`}
                    className="btn btn-primary btn-lg"
                    hidden={isDisabled}
                    onClick={() => setModalShow(true)}
                  >
                    <i className="bi bi-camera"></i>{" "}
                    {uploading
                      ? "Uploading..."
                      : formValues?.image
                      ? "Change picture"
                      : "Take A Picture"}
                  </label>
                ) : (
                  <>
                    <label
                      htmlFor={`picture`}
                      className="btn btn-primary btn-lg"
                      hidden={isDisabled}
                      onClick={() => setModalShow(true)}
                    >
                      <i className="bi bi-camera"></i>{" "}
                      {uploading
                        ? "Uploading..."
                        : formValues?.image
                        ? "Change picture"
                        : "Take A Picture"}
                    </label>
                    {/* <label
                      className="btn btn-primary btn-lg d-none"
                      onClick={() => startLivenessTest()}
                    >
                      <i className="bi bi-camera"></i>{" "}
                      {uploading
                        ? "Uploading..."
                        : formValues?.image
                        ? "Liveliness Check"
                        : "Liveliness Check"}
                    </label> */}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <ul
        id="basicStepFormProgress"
        className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-7 d-none d-md-block d-lg-block"
      >
        <li className="step-item">
          <div
            className="step-content-wrapper"
            data-hs-step-form-next-options='{
          "targetSelector": "#basicStepDetails"
        }'
          >
            <div className="step-content pt-1">
              <span
                className="step-title pt-1 active"
                style={{ color: "#1F7AFF" }}
              >
                Next of kin
              </span>
            </div>
          </div>
        </li>

        <li className="step-item">
          <div
            className="step-content-wrapper"
            data-hs-step-form-next-options='{
           "targetSelector": "#basicStepTerms"
         }'
          ></div>
        </li>
      </ul>

      <span
        className="step-title pt-1 active d-sm-block d-md-none d-lg-none mb-5"
        style={{ color: "#1F7AFF" }}
      >
        Next of kin
      </span>

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="nok_firstname">
            First Name*
          </label>
          <input
            type="text"
            className={`form-control  ${
              formValues?.nextOfKin?.nok_firstname &&
              formValues?.nextOfKin?.nok_firstname?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.nextOfKin?.nok_firstname &&
              data?.nextOfKin?.nok_firstname?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.nextOfKin?.nok_firstname &&
              formValues?.nextOfKin?.nok_firstname?.length > 0 &&
              data?.nextOfKin?.nok_firstname &&
              data?.nextOfKin?.nok_firstname?.length > 0 &&
              isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="nok_firstname"
            id="nok_firstname"
            tabIndex={1}
            defaultValue={data?.nextOfKin?.nok_firstname}
            placeholder=""
            aria-label="nok_firstname"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="nok_lastname">
            Last Name*
          </label>
          <input
            type="text"
            className={`form-control  ${
              formValues?.nextOfKin?.nok_lastname &&
              formValues?.nextOfKin?.nok_lastname?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.nextOfKin?.nok_lastname &&
              data?.nextOfKin?.nok_lastname?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.nextOfKin?.nok_lastname &&
              formValues?.nextOfKin?.nok_lastname?.length > 0 &&
              data?.nextOfKin?.nok_lastname &&
              data?.nextOfKin?.nok_lastname?.length > 0 &&
              isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="nok_lastname"
            id="nok_lastname"
            tabIndex={1}
            defaultValue={data?.nextOfKin?.nok_lastname}
            placeholder=""
            aria-label="nok_lastname"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
      </div>

      <div
        className="row mb-3 mb-md-4 mb-lg-4"
        hidden={
          isDisabled === true && !data?.nextOfKin?.nok_middlename ? true : false
        }
      >
        <div className="col">
          <label className="form-label" htmlFor="nok_middlename">
            Middle name
          </label>
          <input
            type="text"
            className={`form-control ${
              formValues?.nextOfKin?.nok_middlename &&
              formValues?.nextOfKin?.nok_middlename?.length > 0 &&
              isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.nextOfKin?.nok_middlename &&
              data?.nextOfKin?.nok_middlename?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            }  ${
              formValues?.nextOfKin?.nok_middlename &&
              formValues?.nextOfKin?.nok_middlename?.length > 0 &&
              data?.nextOfKin?.nok_middlename &&
              data?.nextOfKin?.nok_middlename?.length > 0 &&
              isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="nok_middlename"
            id="nok_middlename"
            tabIndex={1}
            defaultValue={data?.nextOfKin?.nok_middlename}
            placeholder=""
            aria-label="middle name"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
      </div>

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="nok_email">
            Email*
          </label>
          <input
            type="email"
            className={`form-control ${
              formValues?.nextOfKin?.nok_email &&
              formValues?.nextOfKin?.nok_email?.length > 0 &&
              validateEmail(formValues?.nextOfKin?.nok_email) === true &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.nextOfKin?.nok_email &&
              formValues?.nextOfKin?.nok_email?.length > 0 &&
              validateEmail(formValues?.nextOfKin?.nok_email) === false &&
              !isDisabled
                ? "border border-danger "
                : "border border-secondary"
            } ${
              data?.nextOfKin?.nok_email?.length > 0
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="nok_email"
            id="nok_email"
            tabIndex={1}
            defaultValue={data?.nextOfKin?.nok_email}
            placeholder=""
            aria-label="nok_email"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
          <span className="invalid-feedback">
            Please enter a valid email address.
          </span>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 mb-3 mb-md-3 mb-lg-0">
          <label className="form-label" htmlFor="mobile_number">
            Phone Number*
          </label>
          <PhoneInput
            country={"ng"}
            value={data?.nextOfKin?.nok_mobile_number}
            disabled={isDisabled}
            onChange={(phoneNumber: any) => {
              const e: any = {
                target: {
                  name: "nok_mobile_number",
                  value: phoneNumber,
                  id: "nok_mobile_number",
                },
              };
              handleChange(e);
            }}
            inputClass={`form-control form-control-lg w-100  ${
              formValues?.nextOfKin?.nok_mobile_number &&
              formValues?.nextOfKin?.nok_mobile_number?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.nextOfKin?.nok_mobile_number &&
              data?.nextOfKin?.nok_mobile_number?.length > 0 &&
              !isDisabled
                ? "border border-succes"
                : "border border-secondary"
            } ${
              formValues?.nextOfKin?.nok_mobile_number &&
              formValues?.nextOfKin?.nok_mobile_number?.length > 0 &&
              data?.nextOfKin?.nok_mobile_number &&
              data?.nextOfKin?.nok_mobile_number?.length > 0 &&
              isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
          />
        </div>
      </div>

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col">
          <label className="form-label" htmlFor="nok_address">
            House address*
          </label>
          <input
            type="text"
            className={`form-control ${
              formValues?.nextOfKin?.nok_address &&
              formValues?.nextOfKin?.nok_address?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.nextOfKin?.nok_address &&
              data?.nextOfKin?.nok_address?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.nextOfKin?.nok_address &&
              data?.nextOfKin?.nok_address &&
              data?.nextOfKin?.nok_address?.length > 0 &&
              isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            name="nok_address"
            id="nok_address"
            tabIndex={1}
            defaultValue={data?.nextOfKin?.nok_address}
            placeholder=""
            aria-label="nok_address"
            onChange={handleChange}
            required
            readOnly={isDisabled}
          />
        </div>
      </div>

      <div className="row mb-3 mb-md-4 mb-lg-4">
        <div className="col">
          <label className="form-label" htmlFor="nok_relationship">
            Relationship*
          </label>
          <select
            className={`form-select form-control ${
              formValues?.nextOfKin?.nok_relationship &&
              formValues?.nextOfKin?.nok_relationship?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              data?.nextOfKin?.nok_relationship &&
              data?.nextOfKin?.nok_relationship?.length > 0 &&
              !isDisabled
                ? "border border-secondary "
                : "border border-secondary"
            } ${
              formValues?.nextOfKin?.nok_relationship &&
              data?.nextOfKin?.nok_relationship &&
              data?.nextOfKin?.nok_relationship?.length > 0 &&
              isDisabled
                ? "border border-secondary"
                : "border border-secondary"
            }`}
            id="nok_relationship"
            name="nok_relationship"
            onChange={(e) => handleChange(e)}
            defaultValue={data?.nextOfKin?.nok_relationship}
            disabled={isDisabled}
          >
            <option value="">Select</option>
            {RELATIONSHIP_WITH_NOK.map((item: any): JSX.Element => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

export default MandatoryUnregisteredFields;
