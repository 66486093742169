import moengage from "@moengage/web-sdk";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import AirtelIcon from "../../assets/airtel.svg";
import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete-icon.svg";
import MtnIcon from "../../assets/mtn.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import NineMobileIcon from "../../assets/svg/9mobile.svg";
import GloIcon from "../../assets/svg/glo.svg";
import SpinnerComponent from "../../component/spinner/Spinner";
import {
  convertToInternationalFormat,
  formatAmount,
  formatAmountWithDecimal,
  isValidNigeriaPhoneNumber,
} from "../../helpers/index.helper";
import useUserDeviceInfo from "../../hooks/device.hook";
import {
  // useAirtimeNumberInfo,
  useConfirmAirtimePurchase,
  usePrepareAirtimePurchase,
} from "../../hooks/top-up/index.hook";
import API_SERVICE from "../../services/services";
import { allowOnlyNumericValues } from "../../utils/decode";
import PurchaseModal from "./PurchaseModal";
import SaveBeneficiaryModal from "./SaveBeneficiaryModal";
import SavedBeneficiaryModal from "./SavedBeneficiaryModal";
import SuccessfulPurchaseModal from "./SuccessfulPurchaseModal";

interface FormData {
  mobile_number: string;
  network_provider?: string;
  data_plan?: string;
  amount: string;
  purchase_id: string;
}

interface NetworkProvider {
  name: string;
  icon?: any;
}

interface AirtimeTopUpFormProps {
  formData: FormData;
  providers: NetworkProvider[];
  setFormData: (prevData: FormData) => void;
  size: any;
  index: number;
  setIsSavedBeneficiary: (val: boolean) => void;
}

export const providers = [
  {
    name: "MTN",
    icon: MtnIcon,
  },
  {
    name: "Globacom",
    icon: GloIcon,
  },
  {
    name: "Airtel",
    icon: AirtelIcon,
  },
  {
    name: "9mobile",
    icon: NineMobileIcon,
  },
];

const Airtime = ({ stage, setStage, handleRefetch }: any) => {
  const { deviceInfo } = useUserDeviceInfo();
  const queryClient = useQueryClient();
  const { resolve_prepare_purchase_airtime } = usePrepareAirtimePurchase();
  const { resolve_confirm_purchase_airtime } = useConfirmAirtimePurchase();
  const [size, setSize] = React.useState([0, 0]);
  const [beneficiary, setBeneficiary] = React.useState<any>([]);
  const [batchDate, setBatchData] = React.useState<any[]>([]);
  const [confirmedBatch, setConfirmedBatch] = React.useState(null);
  const [formsData, setFormsData] = React.useState<FormData[]>([
    {
      mobile_number: "",
      network_provider: "",
      data_plan: "",
      amount: "",
      purchase_id: "",
    },
  ]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const handleAddMore = () => {
    setFormsData((prevForms) => [
      ...prevForms,
      {
        /* initial state for a new form */
        mobile_number: "",
        network_provider: "",
        data_plan: "",
        amount: "",
        purchase_id: "",
      },
    ]);
    scrollDownToElement("#scroll-body");
  };

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isSavedBeneficiary, setIsSavedBeneficiary] = useState<boolean>(false);
  const [canSaveBeneficiary, setCanSaveBeneficiary] = useState<boolean>(false);
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setStage("init");
  };
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const [showProceed, setShowProceed] = React.useState<boolean>(false);
  const [showSaveBeneficiaryModal, setShowSaveBeneficiaryModal] =
    React.useState<boolean>(false);
  const handleOpenSaveBeneficiaryModal = () => {
    setShowSuccessModal(false);
    setShowSaveBeneficiaryModal(true);
  };
  const handleCloseSaveBeneficiaryModal = () => {
    setShowSaveBeneficiaryModal(false);
    setStage("init");
  };
  const handleCloseProceed = () => {
    setShowProceed(false);
    if (size?.[0] <= 998) setStage("init");
  };
  const handleShowProceed = async () => {
    try {
      setShowProceed(true);
      if (size?.[0] <= 998) setStage("pay");
    } catch (e) {
      return e;
    }
  };
  const handlePay = async (transaction_pin: string) => {
    try {
      const res = await resolve_prepare_purchase_airtime.mutateAsync(batchDate);
      const id = res?.data?.data?.batch_id ?? res?.data?.data?.[0]?.reference;
      await resolve_confirm_purchase_airtime.mutateAsync({
        transaction_pin,
        id,
      });
      moengage.track_event("INITIATE_AIRTIME_TOPUP", {
        success_status: true,
        platform: deviceInfo?.userAgent,
        ...formsData,
      });
      queryClient.invalidateQueries({
        queryKey: ["airtime", 0],
      });
      queryClient.invalidateQueries({
        queryKey: ["data", 0],
      });
      handleRefetch();
      if (!isSavedBeneficiary) setCanSaveBeneficiary(batchDate.length < 2);
      setBeneficiary({ ...formsData?.[0], type: "airtime" });
      setFormsData([
        {
          mobile_number: "",
          network_provider: "",
          data_plan: "",
          amount: "",
          purchase_id: "",
        },
      ]);
      setBatchData([]);
      if (size?.[0] > 998) setShowProceed(false);
      handleShowSuccessModal();
    } catch (e) {
      return e;
    }
  };
  const handleReset = () => {
    setFormsData([
      {
        mobile_number: "",
        network_provider: "",
        data_plan: "",
        amount: "",
        purchase_id: "",
      },
    ]);
    setBatchData([]);
  };

  function scrollDownToElement(elementSelector: string) {
    window.location.href = `#last-one`;
  }

  return (
    <React.Fragment>
      {showSaveBeneficiaryModal && (
        <SaveBeneficiaryModal
          show={showSaveBeneficiaryModal}
          size={size?.[0]}
          handleClose={handleCloseSaveBeneficiaryModal}
          beneficiary={beneficiary}
        />
      )}
      {showProceed && (
        <>
          {size?.[0] <= 998 && stage === "pay" && (
            <PurchaseModal
              show={showProceed}
              handleClose={handleCloseProceed}
              handlePay={handlePay}
              handleRefetch={handleRefetch}
              processor={resolve_confirm_purchase_airtime}
              data={confirmedBatch}
              noModal={true}
              size={size}
              formsData={formsData}
              title="data"
              isDisabled={
                resolve_prepare_purchase_airtime.isLoading ||
                resolve_confirm_purchase_airtime.isLoading
              }
            />
          )}
          {size?.[0] > 998 && (
            <PurchaseModal
              show={showProceed}
              handleClose={handleCloseProceed}
              handlePay={handlePay}
              handleRefetch={handleRefetch}
              processor={resolve_confirm_purchase_airtime}
              data={confirmedBatch}
              noModal={false}
              formsData={formsData}
              size={size}
              isDisabled={
                resolve_prepare_purchase_airtime.isLoading ||
                resolve_confirm_purchase_airtime.isLoading
              }
            />
          )}
        </>
      )}
      <SuccessfulPurchaseModal
        show={showSuccessModal}
        handleClose={handleCloseSuccessModal}
        handleSaveBeneficiary={handleOpenSaveBeneficiaryModal}
        handleReset={() => handleReset()}
        canSaveBeneficiary={canSaveBeneficiary}
        size={size}
      />
      {/* form(s) */}
      {stage !== "pay" && stage !== "history" && (
        <>
          <div
            style={
              size?.[0] <= 998
                ? {}
                : {
                    maxHeight: "60vh",
                    overflowY: "auto",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  }
            }
            id="scroll-body"
          >
            {formsData.map((singleFormData, index) => (
              <div>
                <div className={`w-100 position-relative`}>
                  {/* delete form button */}
                  {index !== 0 && (
                    <div
                      className="position-absolute end-0 d-flex justify-content-center align-items-center"
                      onClick={() => {
                        const newFormsData = formsData.filter(
                          (_, i) => i !== index
                        );
                        setFormsData(newFormsData);
                        const newBatchData = batchDate.filter(
                          (_, i) => i !== index
                        );
                        setBatchData(newBatchData);
                      }}
                      style={{
                        top: size?.[0] <= 998 ? "-45px" : "-15px",
                        background: "#FF003D",
                        padding: "4px",
                        borderRadius: "8px",
                        gap: "10px",
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  )}

                  <AirtimeTopUpForm
                    key={index}
                    index={index}
                    formData={singleFormData}
                    providers={providers}
                    setIsSavedBeneficiary={setIsSavedBeneficiary}
                    setFormData={(updatedData) => {
                      const newFormsData = [...formsData];
                      newFormsData[index] = updatedData;
                      setFormsData(newFormsData);
                      setBatchData(
                        newFormsData.map((item: any) => ({
                          mobile_number: convertToInternationalFormat(
                            item.mobile_number
                          ),
                          purchase_id: item.purchase_id,
                          amount: item.amount,
                        }))
                      );
                    }}
                    size={size}
                  />
                </div>
                <div id={formsData.length - 1 === index ? `last-one` : ""} />
                {index !== formsData.length - 1 && (
                  <hr style={{ color: "#DDE7FB" }} />
                )}
              </div>
            ))}
          </div>
          <br />

          {/* action buttons */}
          {size?.[0] <= 998 ? (
            <>
              <div
                style={{
                  width: "100%",
                  boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.08)",
                  gap: 14,
                  left: 0,
                  right: 0,
                  padding: "22px 27px 51px",
                }}
                className="d-flex flex-column bottom-0 position-fixed bg-white"
              >
                <div className="d-flex flex-row" style={{ gap: 14 }}>
                  <button
                    className="btn fw-bolder"
                    onClick={handleAddMore}
                    disabled={
                      formsData.length === 10 ||
                      formsData.filter(
                        (form) =>
                          !form.amount ||
                          !form.mobile_number ||
                          !form.network_provider ||
                          !form.purchase_id
                      )?.length > 0 ||
                      !isValidNigeriaPhoneNumber(
                        formsData.map((item) =>
                          convertToInternationalFormat(item?.mobile_number)
                        )
                      )
                    }
                    style={{
                      flex: "1 1 0",
                      height: 40,
                      boxShadow: "2px 2px 0px rgba(0, 0, 0, 0.05)",
                      borderRadius: 8,
                      overflow: "hidden",
                      border: "1px #D0D5E9 solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        flex: "1 1 0",
                        height: 40,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 10,
                        paddingBottom: 10,
                        background: "white",
                        borderRadius: 8,
                        overflow: "hidden",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 4,
                        display: "flex",
                      }}
                    >
                      <PlusIcon className="w-12 h-12" />
                      Add more
                    </div>
                  </button>
                  <div
                    style={{
                      flex: "1 1 0",
                      height: 40,
                      borderRadius: 4,
                      overflow: "hidden",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <button
                      type={"button"}
                      className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                      disabled={
                        resolve_prepare_purchase_airtime.isLoading ||
                        resolve_confirm_purchase_airtime.isLoading ||
                        formsData.filter(
                          (form) =>
                            !form.amount ||
                            !form.mobile_number ||
                            !form.network_provider ||
                            !form.purchase_id
                        )?.length > 0 ||
                        !isValidNigeriaPhoneNumber(
                          formsData.map((item) =>
                            convertToInternationalFormat(item?.mobile_number)
                          )
                        )
                      }
                      onClick={handleShowProceed}
                      style={{
                        borderRadius: "8px",
                        background:
                          resolve_prepare_purchase_airtime.isLoading ||
                          resolve_confirm_purchase_airtime.isLoading ||
                          formsData.filter(
                            (form) =>
                              !form.amount ||
                              !form.mobile_number ||
                              !form.network_provider ||
                              !form.purchase_id
                          )?.length > 0 ||
                          !isValidNigeriaPhoneNumber(
                            formsData.map((item) =>
                              convertToInternationalFormat(item?.mobile_number)
                            )
                          )
                            ? "#D0D5E9"
                            : "#2C1DFF",
                      }}
                    >
                      Proceed
                      <span className="align-middle ml-2">
                        &emsp;
                        {(resolve_prepare_purchase_airtime.isLoading ||
                          resolve_confirm_purchase_airtime.isLoading) && (
                          <SpinnerComponent size="sm" />
                        )}
                      </span>
                    </button>
                  </div>
                </div>
                {Boolean(formsData.length === 10) && (
                  <span className="mt-1 form-text">
                    Maximum of 10 recipients allowed
                  </span>
                )}
              </div>
            </>
          ) : (
            <div
              style={{
                paddingLeft: "10%",
                paddingRight: "10%",
              }}
            >
              <button
                className="btn fw-bolder"
                onClick={handleAddMore}
                disabled={
                  formsData.length === 10 ||
                  formsData.filter(
                    (form) =>
                      !form.amount ||
                      !form.mobile_number ||
                      !form.network_provider ||
                      !form.purchase_id
                  )?.length > 0 ||
                  !isValidNigeriaPhoneNumber(
                    formsData.map((item) =>
                      convertToInternationalFormat(item?.mobile_number)
                    )
                  )
                }
                style={{
                  color: "black",
                  border: "1px solid #CBD1EC",
                  width: "116px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  borderRadius: "8px",
                  gap: "4px",
                  boxShadow: "2px 2px 0px 0px rgba(0, 0, 0, 0.05)",
                }}
              >
                <PlusIcon className="w-12 h-12" />
                Add more
              </button>
              {Boolean(formsData.length === 10) && (
                <span className="mt-1 form-text">
                  Maximum of 10 recipients allowed
                </span>
              )}
              <div
                className="mt-10"
                style={{
                  paddingBottom: "50px",
                }}
              >
                <button
                  type={"button"}
                  className={`btn w-100 d-flex items-center justify-content-center text-white fw-bolder`}
                  disabled={
                    resolve_prepare_purchase_airtime.isLoading ||
                    resolve_confirm_purchase_airtime.isLoading ||
                    formsData.filter(
                      (form) =>
                        !form.amount ||
                        !form.mobile_number ||
                        !form.network_provider ||
                        !form.purchase_id
                    )?.length > 0 ||
                    !isValidNigeriaPhoneNumber(
                      formsData.map((item) =>
                        convertToInternationalFormat(item?.mobile_number)
                      )
                    )
                  }
                  onClick={handleShowProceed}
                  style={{
                    borderRadius: "8px",
                    background:
                      resolve_prepare_purchase_airtime.isLoading ||
                      resolve_confirm_purchase_airtime.isLoading ||
                      formsData.filter(
                        (form) =>
                          !form.amount ||
                          !form.mobile_number ||
                          !form.network_provider ||
                          !form.purchase_id
                      )?.length > 0 ||
                      !isValidNigeriaPhoneNumber(
                        formsData.map((item) =>
                          convertToInternationalFormat(item?.mobile_number)
                        )
                      )
                        ? "#D0D5E9"
                        : "#2C1DFF",
                  }}
                >
                  Proceed{" "}
                  <span className="align-middle ml-2">
                    &emsp;
                    {(resolve_prepare_purchase_airtime.isLoading ||
                      resolve_confirm_purchase_airtime.isLoading) && (
                      <SpinnerComponent size="sm" />
                    )}
                  </span>
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

const AirtimeTopUpForm: React.FC<AirtimeTopUpFormProps> = ({
  formData,
  index,
  providers,
  setFormData,
  setIsSavedBeneficiary,
  size,
}) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [maxAirtimePurchase, setMaxNumber] = useState(0);
  const [numberAirtime, setNumberAirtime] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleOpen = () => {
    setShow(true);
    moengage.track_event("SELECT_BENEFICIARY", {
      success_status: true,
    });
  };

  return (
    <>
      <SavedBeneficiaryModal
        selectNumber={async (number) => {
          setFormData({ ...formData, mobile_number: number });
          if (
            (number?.startsWith("0") && number?.length === 11) ||
            (!number?.startsWith("0") && number?.length === 10)
          ) {
            setLoading(true);
            setIsSavedBeneficiary(true);
            const res = await API_SERVICE._getNumberInfo(
              convertToInternationalFormat(allowOnlyNumericValues(number))
            );
            setFormData({
              ...formData,
              network_provider: res?.data?.data?.telco,
              purchase_id: res?.data?.data?.purchase_id,
              mobile_number: allowOnlyNumericValues(number),
              amount: "",
            });
            setMaxNumber(res?.data?.data?.max_purchase);
            setLoading(false);
          }
          handleClose();
        }}
        show={show}
        index={index}
        size={size?.[0]}
        handleClose={handleClose}
      />
      {/* phone number */}
      <div className="mb-4 overflow-hidden d-flex flex-column">
        <label className="form-label fw-semibold" htmlFor="mobile_number">
          Phone number*
        </label>
        <div className="d-flex w-100">
          <select
            className="form-select"
            style={{
              border: "1px solid #CBD1EC",
              borderRadius: "8px",
              height: "42px",
              width: "25%",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
            // Add proper id, name, value, and onChange handler
          >
            <option>+234</option>
          </select>
          <input
            className="w-100 fw-normal"
            id="mobile_number"
            maxLength={11}
            value={formData.mobile_number}
            onChange={async (e) => {
              try {
                setFormData({
                  ...formData,
                  mobile_number: allowOnlyNumericValues(e.target.value),
                  network_provider: "",
                });
                if (
                  (allowOnlyNumericValues(e.target.value)?.startsWith("0") &&
                    allowOnlyNumericValues(e.target.value)?.length === 11) ||
                  (!allowOnlyNumericValues(e.target.value)?.startsWith("0") &&
                    allowOnlyNumericValues(e.target.value)?.length === 10)
                ) {
                  setLoading(true);
                  const res = await API_SERVICE._getNumberInfo(
                    convertToInternationalFormat(
                      allowOnlyNumericValues(e.target.value)
                    )
                  );
                  setFormData({
                    ...formData,
                    network_provider: res?.data?.data?.telco,
                    purchase_id: res?.data?.data?.purchase_id,
                    mobile_number: allowOnlyNumericValues(e.target.value),
                    amount: "",
                  });
                  setMaxNumber(res?.data?.data?.max_purchase);
                  setLoading(false);
                  setIsSavedBeneficiary(false);
                }
              } catch (e: any) {
                console.log(e?.response?.data?.data, "error");
                toast.error(
                  e?.response?.data?.data ||
                    "Issue fetching Phone number Details",
                  {
                    autoClose: 3000,
                  }
                );
                setLoading(false);
                setIsSavedBeneficiary(false);
              }
            }}
            style={{
              height: "42px",
              border: "1px solid #CBD1EC",
              borderRadius: "8px",
              borderLeft: "none",
              padding: "8px",
              fontSize: "16px",
              color: "#000000",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              lineHeight: "24px",
              letterSpacing: "-0.16px",
            }}
          />
        </div>
        {formData.mobile_number &&
          !isValidNigeriaPhoneNumber([
            convertToInternationalFormat(formData.mobile_number),
          ]) && (
            <span className="mt-1 form-text" style={{ color: "red" }}>
              Please enter a valid phone number
            </span>
          )}
      </div>

      {/* select beneficiaries */}
      {size?.[0] > 998 ? (
        <div
          className="d-flex w-auto"
          style={{
            justifyContent: size?.[0] <= 998 ? "center" : "flex-start",
          }}
        >
          <p
            className="link link-primary fw-normal"
            onClick={() => {
              setShow(true);
              moengage.track_event("SELECT_BENEFICIARY", {
                success_status: true,
              });
            }}
            style={{
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            Select beneficiary
          </p>
        </div>
      ) : (
        <div
          className="d-flex w-auto"
          style={{
            justifyContent: size?.[0] <= 998 ? "center" : "flex-start",
          }}
        >
          <p
            className="link link-primary fw-normal"
            onClick={handleOpen}
            style={{
              cursor: "pointer",
              width: "fit-content",
            }}
          >
            Select beneficiary
          </p>
        </div>
      )}

      {/* select network provider */}
      <div className="d-flex flex-column w-100 my-5">
        <label className="form-label fw-semibold" htmlFor="network_provider">
          Select network provider
        </label>
        <div className="d-flex gap-3 justify-content-between">
          {providers.map((provider, idx) => (
            <div
              key={idx}
              className={`position-relative d-flex flex-column align-items-center justify-content-center bg-body border border-2 ${
                formData.network_provider === provider.name
                  ? "border-primary"
                  : ""
              }`}
              style={{
                width: "20%",
                height: "64px",
                cursor: "pointer",
                borderRadius: "8px",
                padding: "12px 15px",
              }}
              onClick={() =>
                setFormData({ ...formData, network_provider: provider.name })
              }
            >
              {formData.network_provider === provider.name && (
                <span
                  className="position-absolute  translate-middle"
                  style={{
                    top: "8px",
                    left: "8px",
                  }}
                >
                  <Check />
                  <span className="visually-hidden">Selected</span>
                </span>
              )}
              <div
                className="text-black fw-normal d-flex justify-content-center flex-column align-items-center gap-1"
                style={{ fontSize: "10px" }}
              >
                <img src={provider.icon} alt={provider.name} />
                {provider.name}
              </div>
            </div>
          ))}
        </div>

        {loading && (
          <div className="d-flex mt-2 justify-content-center">
            <SpinnerComponent />
          </div>
        )}
      </div>

      {/* amount */}
      <div className="mb-6 overflow-hidden d-flex flex-column">
        <label className="form-label fw-semibold" htmlFor="amount">
          Amount*
        </label>
        <div className="d-flex w-100">
          <select
            className={`form-select`}
            id=""
            value={formData.amount}
            name=""
            style={{
              border: "1px solid #CBD1EC",
              borderRadius: "8px",
              width: "27%",
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
          >
            <option>NGN</option>
          </select>
          <input
            className="w-100 fw-normal"
            type="text"
            value={formatAmountWithDecimal(formData.amount)}
            disabled={!Boolean(maxAirtimePurchase)}
            max={maxAirtimePurchase}
            onChange={(e: any) => {
              if (
                maxAirtimePurchase >=
                Number(allowOnlyNumericValues(e.target.value))
              )
                setFormData({
                  ...formData,
                  amount: allowOnlyNumericValues(e.target.value),
                });
            }}
            style={{
              height: "43px",
              border: "1px solid #CBD1EC",
              borderRadius: "8px",
              borderLeft: "none",
              padding: "8px",
              fontSize: "16px",
              borderTopLeftRadius: "0px",
              borderBottomLeftRadius: "0px",
              lineHeight: "24px",
              letterSpacing: "-0.16px",
              color: "#000000",
            }}
          />
        </div>
        {Boolean(maxAirtimePurchase) && (
          <span className="mt-1 form-text">
            Max purchasable value: {formatAmount(maxAirtimePurchase)}
          </span>
        )}
      </div>

      {size?.[0] <= 998 && (
        <div className="d-flex flex-column w-100 my-5">
          <label className="form-label fw-semibold" htmlFor="network_provider">
            Select amount
          </label>
          <div className="d-flex gap-3 flex-wrap">
            {["100", "200", "500", "1000", "2000", "3000"].map((item) => (
              <button
                onClick={() => setFormData({ ...formData, amount: item })}
                disabled={!maxAirtimePurchase}
                style={
                  formData.amount === item
                    ? {
                        width: "30%",
                        height: "40px",
                        position: "relative",
                        background: "#FFF",
                        borderRadius: 8,
                        overflow: "hidden",
                        border: "1px #2C1DFF solid",
                        color: "#2C1DFF",
                        fontWeight: "600",
                        fontSize: 14,
                      }
                    : {
                        width: "30%",
                        height: "40px",
                        position: "relative",
                        background: "#FFF",
                        borderRadius: 8,
                        overflow: "hidden",
                        border: "none",
                        fontWeight: "600",
                        fontSize: 14,
                      }
                }
                className="shadow"
              >
                {formatAmount(Number(item))}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Airtime;
