import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { ReactComponent as Cloud } from "../../assets/cloud.svg";
import {
  compressFile,
  encryptKyc,
  mode,
  signatureHarsh,
} from "../../helpers/index.helper";
import "./dropzone.css";
import {TOKEN_STRING} from "../../constants/index.constant";

type Props = {
  onFileExport?: any;
  isDisabled?: boolean;
  fileName?: string;
};

function Dropzone({ onFileExport, isDisabled, fileName }: Props): JSX.Element {
  const [progress, setProgress] = useState<any>(0);
  const [files, setFiles] = useState<any>([]);

  const baseUrl =
    mode === "customer.test"
      ? process.env.REACT_APP_KYC
      : process.env.REACT_APP_BASE_URL;
  const Id: any = localStorage.getItem("merchant_compliance_id");
  const message = Id && JSON.stringify({ id: Id });
  const x_signature = encryptKyc(message, signatureHarsh);
  const url =
    mode === "customer.test"
      ? `${baseUrl}/upload/${Id}`
      : `${baseUrl}/kyc/upload/${Id}`;

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      if (acceptedFiles) {
        const acceptedFilesMap = acceptedFiles.map((file: any) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        );
        setFiles(acceptedFilesMap);
        const formData = new FormData();
        const compressedFile = await compressFile(acceptedFiles[0]);

        formData.append("file", acceptedFiles[0]);

        try {
          const response = await axios.post(url, formData, {
            headers: { "x-compliance-signature": x_signature, "Authorization": localStorage.getItem(TOKEN_STRING) },
            onUploadProgress: (progressEvent: any) => {
              const progressUpdate =
                (progressEvent.loaded / progressEvent.total) * 100;
              setProgress(progressUpdate);
            },
          });
          const imageUrl = response?.data?.data?.url;
          if (imageUrl) {
            toast.success("File uploaded successfully.");
            onFileExport(imageUrl);
          }
        } catch (error) {
          toast.error("File upload failed");
          throw error;
        }
      }
    },
    [Id, baseUrl, onFileExport, x_signature]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 50000000,
    accept: {
      "image/png*": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
  });

  useEffect(() => {
    if (fileName) {
      setFiles([{ name: fileName }]);
    }
  }, [fileName]);

  return (
    <form>
      {isDisabled === true ? (
        <>
          <ul className="list-unstyled pt-2">
            <li className="d-flex justify-content-between align-items-start">
              <span
                className="file-name me-2 py-2 ps-2 pe-2 d-flex justify-content-between align-items-start"
                // style={{ width: `${progress}%` }}
              >
                <span
                  className="d-inline-block text-truncate"
                  style={{ maxWidth: "250px" }}
                >
                  {fileName}
                </span>
              </span>
            </li>
          </ul>
        </>
      ) : (
        <>
          {" "}
          <div {...getRootProps({ className: "dropzone-component py-5 " })}>
            <input {...getInputProps()} />
            <div className="mx-auto text-center">
              <Cloud />
              {isDragActive ? (
                <p className="link link-secondary">Drag and drop files here</p>
              ) : (
                <h5 className="text-dark text-center pt-2 click">
                  Click to upload or drag and drop
                </h5>
              )}
              <span className="file-size">
                Max. file size is 5mb. pdf, png, jpg format accepted.
              </span>
            </div>
          </div>
          <ul className="list-unstyled pt-2">
            {fileName && progress === 0 ? (
              <>
                {files.length > 0 &&
                  files.map((file: any) => {
                    return (
                      <li
                        className="d-flex justify-content-between align-items-start"
                        key={file.name}
                      >
                        <span
                          className="file-name me-2 py-2 ps-2 pe-2 d-flex justify-content-between align-items-start"
                          key={file.name}
                          style={{
                            width: fileName ? `${90}%` : `${progress}%`,
                          }}
                        >
                          {/* {progress > 0 ? (
                            <span className="">{file.name}</span>
                          ) : (
                            <span>{truncateString(fileName, 20)}</span>
                          )} */}
                          <span
                            className="d-inline-block text-truncate"
                            style={{ maxWidth: "250px" }}
                          >
                            {file.name}
                          </span>
                        </span>

                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            if (files.length > 0) {
                              let fileData = [...files];
                              fileData.pop();
                              setFiles(fileData);
                            }
                          }}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </li>
                    );
                  })}
              </>
            ) : (
              <>
                {files.length > 0 &&
                  files.map((file: any) => {
                    const size = (file.size / (1024 * 1024))?.toFixed(2);
                    return (
                      <li className="d-flex justify-content-between align-items-start">
                        <span
                          className="file-name me-2 py-2 ps-2 pe-2 d-flex justify-content-between align-items-start"
                          key={file.name}
                          style={{
                            width: `${progress}%`,
                          }}
                        >
                          <span>{file.name}</span>
                          <span
                            className={`${
                              Number.isNaN(size) === false ? "d-none" : ""
                            }`}
                          >
                            {progress === 100 ? `${size}mb` : ""}
                          </span>
                        </span>

                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            if (files.length > 0) {
                              let fileData = [...files];
                              fileData.pop();
                              setFiles(fileData);
                            }
                          }}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </li>
                    );
                  })}
              </>
            )}
          </ul>
        </>
      )}
    </form>
  );
}

export default Dropzone;
